import React, { useState, useEffect } from 'react';
import NavBarProdutos from '../../components/layout/NavBarProdutos';
import Footer from '../../components/layout/Footer';
import ProdutoIcon from '../../assets/icons/shopping-bagicon.png';

// Componente principal que renderiza os produtos
const Produtos = () => {
  // Estados para armazenar os produtos originais, produtos filtrados, produto selecionado e índice da imagem selecionada
  const [produtosOriginais, setProdutosOriginais] = useState([]);
  const [produtosFiltrados, setProdutosFiltrados] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [imagemSelecionada, setImagemSelecionada] = useState(0);

  // Função para lidar com a busca de produtos
  const lidarComBusca = (termoDeBusca) => {
    const termoBuscaLowerCase = termoDeBusca.toLowerCase();
    const filtrados = produtosOriginais.filter((produto) =>
      Object.values(produto).some(
        (valor) => typeof valor === 'string' && valor.toLowerCase().includes(termoBuscaLowerCase)
      )
    );
    setProdutosFiltrados(filtrados);
    setProdutoSelecionado(null);
    rolarParaOTopo();
  };

  // Função para lidar com o clique em um produto
  const lidarComCliqueNoProduto = (produto) => {
    setProdutoSelecionado(produto);
    setImagemSelecionada(0);
    rolarParaOTopo();
  };

  // Função para rolar até o topo da página
  const rolarParaOTopo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Efeito de carregamento inicial dos produtos
  useEffect(() => {
    fetch('./banco.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao carregar o arquivo JSON');
        }
        return response.json();
      })
      .then(data => {
        setProdutosOriginais(data);
        setProdutosFiltrados(data);
      })
      .catch(error => {
        console.error('Ocorreu um erro ao carregar o arquivo JSON:', error);
      });
  }, []);

  // Renderização do componente
  return (
    <>
      {/* Barra de navegação de produtos */}
      <NavBarProdutos onSearch={lidarComBusca} totalResults={produtosFiltrados.length} />

      {/* Seção principal de produtos */}
      <main className="mt-5 mb-5 pt-5">
        {/* Detalhes do produto selecionado */}
        {produtoSelecionado && (
          <section id="detalhes-do-produto" className="mb-5 p-3">
            <div className="row">
              <div className="col-md-6">
                {/* Carrossel de imagens do produto */}
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-inner">
                    {produtoSelecionado.imagens.map((imagem, index) => (
                      <div key={index} className={`carousel-item ${index === imagemSelecionada ? 'active' : ''}`}>
                        <img src={imagem} className="d-block w-100" alt={`Imagem ${index}`} />
                      </div>
                    ))}
                  </div>
                  {/* Controles do carrossel */}
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="bg-secondary rounded-circle fs-2" aria-hidden="true"><i className="bi bi-chevron-left"></i></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="bg-secondary rounded-circle fs-2" aria-hidden="true"><i className="bi bi-chevron-right"></i></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                {/* Detalhes do produto: nome, descrição, preço e botão de compra */}
                <h2 className="card-title fw-bold mt-5">{produtoSelecionado.nome}</h2>
                <p className="card-text mt-4"><b>Descrição:</b> {produtoSelecionado.descricao}</p>
                <p className="card-text"><b>Preço: R$ {produtoSelecionado.preco.toFixed(2)}</b></p>
                <div className="d-flex align-items-center">
                  <p className="card-text mb-3"><b>Cor: {produtoSelecionado.corTexto}</b></p>
                  <div className="ms-2 mb-3 cores" style={{ backgroundColor: produtoSelecionado.corVisual }}></div>
                </div>
                <a
                  href={`https://api.whatsapp.com/send?phone=+5551997045591&text=${encodeURIComponent(
                    `*ID do produto: ${produtoSelecionado.id}*\n\nOlá, estou interessado no produto *${produtoSelecionado.nome}*.\n\n*Descrição:* ${produtoSelecionado.descricao}\n\n*Cor: ${produtoSelecionado.corTexto}*\n\n*Preço: R$${produtoSelecionado.preco.toFixed(2)}*\n\nPoderiam me ajudar?`
                  )}`}
                  className="btn btn-success"
                >
                  <i className="bi bi-whatsapp"></i> Compre Agora
                </a>
              </div>
            </div>
          </section>
        )}

        {/* Cabeçalho da seção de produtos */}
        <h2 className="mb-4 mt-5 d-flex justify-content-center align-items-center bg-dark text-light p-1">
          Diversos Produtos <img src={ProdutoIcon} className='icons' alt="Produto Icon" />
        </h2>

        {/* Lista de produtos */}
        <div className="row g-4 bg-light p-4">
          {produtosFiltrados.map((produto) => (
            <div key={produto.id} className="col-md-4 col-lg-3 col-sm-4 col-6 cursor">
              <div className="card h-100 p-3" onClick={() => lidarComCliqueNoProduto(produto)}>
                <img src={produto.imagens[0]} alt={produto.nome} className="card-img-top p-2" />
                <div className="card-body">
                  <h5 className="card-title fw-bold text-center">{produto.nome}</h5>
                  <p className="card-text text-truncate mt-4"><b>Descrição:</b> {produto.descricao}</p>
                  <p className="card-text"><b>Preço: R$ {produto.preco.toFixed(2)}</b></p>
                  <div className="d-flex align-items-center">
                    <p className="card-text mb-3"><b>Cor: {produto.corTexto}</b></p>
                    <div className="ms-2 mb-3 cores" style={{ backgroundColor: produto.corVisual }}></div>
                  </div>
                  <a href={`https://api.whatsapp.com/send?phone=+5551997045591&text=${encodeURIComponent(
                      `*ID do roduto: ${produto.id}*\n\nOlá, estou interessado no produto *${produto.nome}*.\n\n*Descrição:* ${produto.descricao}\n\n*Cor: ${produto.corTexto}*\n\n*Preço: R$${produto.preco.toFixed(2)}*\n\nPoderiam me ajudar?`
                    )}`}
                    className="btn btn-success"
                  >
                    <i className="bi bi-whatsapp"></i> Compre Agora
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

      </main>

      {/* Rodapé */}
      <Footer />
    </>
  );
};

export default Produtos;