import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Sobre from '../pages/Sobre/Sobre';
import Produtos from '../pages/Produtos/Produtos';
import Home from '../pages/Inicio/Home';
import IphoneProdutos from '../pages/Produtos/IphonesProdutos';
import IpadsProdutos from '../pages/Produtos/IpadsProdutos';
import MacsProdutos from '../pages/Produtos/MacsProdutos';
import WatchsProdutos from '../pages/Produtos/WatchsProdutos';
import AirPodsProdutos from '../pages/Produtos/AirPodsProdutos';
import AcessoriosProdutos from '../pages/Produtos/AcessoriosProdutos';

function Rotas() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/iphones/" element={<IphoneProdutos />} />
          <Route path="/ipads" element={<IpadsProdutos />} />
          <Route path="/macs" element={<MacsProdutos />} />
          <Route path="/watchs" element={<WatchsProdutos />} />
          <Route path="/airpods" element={<AirPodsProdutos />} />
          <Route path="/acessorios" element={<AcessoriosProdutos />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

function NotFound() {
  return (
    <div className="container mt-3">
      <h2>Página não encontrada</h2>
      <p>A página que você está tentando acessar não existe.</p>
    </div>
  );
}

export default Rotas;