// Sobre.js
import React from 'react';
import NavBar from '../../components/layout/NavBar';
import Footer from '../../components/layout/Footer';

const Sobre = () => {
  return (
    <>
      
        <NavBar />

        <main className="container mt-5 main-content">
          <section id="about-us">
            <h2 className="text-center mt-5 mb-5">Sobre Nós</h2>
            <p>Sua principal destinatária de tecnologia e excelência. Nossa missão vai além da venda de produtos Apple - buscamos proporcionar uma experiência única. Oferecemos produtos de qualidade impecável a preços acessíveis, incorporando inovação, conforto e acessibilidade para todos os nossos clientes.</p>

            <p>Na iPhones Store, cada dispositivo é mais do que uma simples compra; é um símbolo de desempenho excepcional e estilo de vida elevado. Comprometemo-nos com a excelência, assegurando que cada transação seja marcada por qualidade e satisfação. Descubra o equilíbrio perfeito entre preço acessível e padrões excepcionais em cada compra.</p>

            <p>Seja parte da nossa comunidade de clientes satisfeitos e mergulhe em uma jornada tecnológica onde a qualidade é a nossa maior prioridade. Na iPhones Store, o seu desejo por inovação e estilo é atendido com perfeição.</p>
          </section>
        </main>

        <br className='br'/>
        <Footer />
      
    </>
  );
};

export default Sobre;