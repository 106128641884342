// Inicio.js
import React, { useState, useEffect } from 'react';
import Footer from '../../components/layout/Footer';
import NavBar from '../../components/layout/NavBar';
import IphoneImage from '../../assets/icons/iphoneicon.png'
import IpadsImage from '../../assets/icons/ipadicon.png'
import MacsImage from  '../../assets/icons/notebookicon.png'
import WatchsImage from '../../assets/icons/smart-watchicon.png'
import AcessóriosImage from '../../assets/icons/micro-usbicon.png'
import AirPodsImage from '../../assets/icons/airpodsicon.png'

const Home = () => {
    
    const [produtoDestaque, setProdutoDestaque] = useState(null);

    useEffect(() => {
        fetch('./ProdutoDestaque/produtoDestaque.json')
        .then(response => {
            if (!response.ok) {
            throw new Error('Erro ao carregar o arquivo JSON');
            }
            return response.json();
        })
        .then(data => {
            setProdutoDestaque(data[0]); // Assumindo que há apenas um produto em destaque no arquivo JSON
        })
        .catch(error => {
            console.error('Ocorreu um erro ao carregar o arquivo JSON:', error);
        });
    }, []);

    const [destaquesSemanais, setDestaquesSemanais] = useState([]);

    useEffect(() => {
      fetch('./DestaquesSemanais/destaqueSemanais.json')
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro ao carregar o arquivo JSON');
          }
          return response.json();
        })
        .then(data => {
          setDestaquesSemanais(data);
        })
        .catch(error => {
          console.error('Ocorreu um erro ao carregar o arquivo JSON:', error);
        });
    }, []);  
    

    return (
        <>
            <NavBar />

            <header className="text-center bg-dark text-white p-5 mt-5">
                <h1 className="display-3">Iphones Store <i className="bi bi-apple"></i></h1>
                <p className="lead">Descubra os melhores produtos Apple para você.</p>
                <div className="container mt-4">
                    <div className="row">
                    <div className="col-md-6 mx-auto">
                        <div className="card">
                        <div className="card-body">
                            {produtoDestaque && (
                            <>
                                <img src={produtoDestaque.imagens} className="img-fluid" alt="Produto" />
                                <h3 className="card-title mt-3"><b>{produtoDestaque.nome}</b></h3>
                            </>
                            )}
                            <h4 className="card-title">Produtos em Destaque</h4>
                            <p className="card-text">Confira nossas promoções especiais e descontos exclusivos esta semana.</p>
                            <a href="/produtos" className="btn btn-dark">Ver Destaques</a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </header>

            <main className="container mt-4">
                <section id="featured-products">
                    <h2 className="mb-4 text-center">Destaques da Semana</h2>

                    <div id="carouselFeaturedProducts" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner rounded">
                            {destaquesSemanais.map((produto, index) => (
                                <div key={produto.id} className={`carousel-item ${index===0 ? 'active' : '' }`}>
                                    <img src={produto.imagens} className="d-block w-100 rounded" alt={`Produto ${index + 1}`} style={{maxHeight: '400px' }} />
                                    <div className="carousel-caption d-md-block">
                                        <h5 className='text-white produto-nome'><b>{produto.nome}</b></h5>
                                        <a className="btn btn-dark" href="/produtos" role="button" data-bs-slide-to={index}>Explorar</a>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselFeaturedProducts" data-bs-slide="prev">
                            <span className="bg-dark rounded-circle fs-2" aria-hidden="true"><i className="bi bi-chevron-left"></i></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselFeaturedProducts" data-bs-slide="next">
                            <span className="bg-dark rounded-circle fs-2" aria-hidden="true"><i className="bi bi-chevron-right"></i></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </section>

                <section id="product-categories" className="mt-4">
                    <h2 className="mb-4 text-center">Categorias de Produtos</h2>
                    <div className="row">
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            <div className="card">
                                <img src={IphoneImage} className="card-img-top p-4" alt="iPhones" />
                                <div className="card-body">
                                    <h5 className="card-title">iPhone</h5>
                                    <a href="/iphones" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            <div className="card">
                                <img src={IpadsImage} className="card-img-top p-4" alt="iPads" />
                                <div className="card-body">
                                    <h5 className="card-title">iPad</h5>
                                    <a href="/ipads" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            <div className="card">
                                <img src={MacsImage} className="card-img-top p-4" alt="MacBooks" />
                                <div className="card-body">
                                    <h5 className="card-title">MacBook</h5>
                                    <a href="/macs" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            <div className="card">
                                <img src={WatchsImage} className="card-img-top p-4" alt="Watchs" />
                                <div className="card-body">
                                    <h5 className="card-title">Watch</h5>
                                    <a href="/watchs" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            
                            <div className="card">
                            <img src={AirPodsImage} className="card-img-top p-4" alt="AirPods" />
                                <div className="card-body">
                                    <h5 className="card-title">AirPods</h5>
                                    <a href="/airpods" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-2 col-6 mb-3">
                            <div className="card">
                                <img src={AcessóriosImage} className="card-img-top p-4" alt="Acessórios" />
                                <div className="card-body">
                                    <h5 className="card-title">Acessórios</h5>
                                    <a href="/acessorios" className="btn btn-dark">Explorar</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </main>

            <Footer />
        </>
    );
};

export default Home;
