import React from 'react';

function Footer() {
    return (
        <footer className="bg-dark text-white text-center p-3">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Informações</h4>
                        <ul className="list-unstyled">
                            <li><a className="link-light link-underline-opacity-0 link-opacity-75-hover" href="/sobre"><i className="bi bi-info-circle"></i> Sobre nós</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 d-flex flex-column mb-3">
                        <h4>Redes Sociais</h4>
                        {/* <!-- Adicione links para suas páginas nas redes sociais --> */}
                        <a className="link-light link-underline-opacity-0 link-opacity-75-hover" href="https://wa.me//5551997045591?text=Estou%20de%20olho%20nos%20seus%20produtos/serviços%20e%20queria%20saber%20mais!%20Quanto%20custa,%20quais%20opções%20tem%20e%20como%20faço%20pra%20comprar?"><i className="bi bi-whatsapp"></i> Whatsapp</a>
                        <a className="link-light link-underline-opacity-0 link-opacity-75-hover" href="https://www.instagram.com/iphones_storenh"><i className="bi bi-instagram"></i> Instagram</a>
                        <a className="link-light link-underline-opacity-0 link-underline-opacity-0 link-opacity-75-hover" href="https://www.tiktok.com/@iphones.store1?_t=8jFxO9faN3W&_r=1"><i className="bi bi-tiktok"></i> TikTok</a>
                    </div>
                    <div className="col-md-4 d-flex flex-column mb-3">
                        <h4>Contato</h4>
                        {/* <!-- Adicione informações de contato --> */}
                        Email: iphonesstorenh@gmail.com <br />
                        Telefone: +55 51 997045591
                    </div>
                    <div>
                        &copy; 2024 KNS. Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer