import React from 'react';
import Rotas from './config/routes';
import './assets/styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  return (
   <>
    <Rotas />
   </> 
  )
}

export default App;
