import React, { useState, useEffect } from 'react';
import Logo from '../../assets/images/Logo.png';

function NavBarProdutos({ onSearch, totalResultados }) {
  const [termoDePesquisa, setTermoDePesquisa] = useState('');

  const lidarComPesquisa = (event) => {
    event.preventDefault();
    onSearch(termoDePesquisa);
  };

  const [expandido, setExpandido] = useState(false);

  useEffect(() => {
    const fecharNavbarComClickFora = (e) => {
      if (expandido && !e.target.closest('.navbar')) {
        setExpandido(false);
      }
    };

    document.body.addEventListener('click', fecharNavbarComClickFora);

    return () => {
      document.body.removeEventListener('click', fecharNavbarComClickFora);
    };
  }, [expandido]);

  return (
    <nav className="navbar navbar-expand-lg bg-light fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="Logo" className="rounded-circle" style={{ width: '40px', height: '40px' }} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={expandido}
          aria-label="Toggle navigation"
          onClick={() => setExpandido(!expandido)}
        >
          <i className={expandido ? 'bi bi-x-lg' : 'bi bi-filter-right'}></i>
        </button>

        <div className={`collapse navbar-collapse justify-content-center${expandido ? ' show' : ''} ${expandido ? ' collapsing' : ''}`} id="navbarNav">
          <ul className="navbar-nav mx-auto gap-2 overflow-auto" style={{ maxHeight: '215px' }}>
            <li className="nav-item"><a className="nav-link link-dark link-opacity-50-hover" href="/"><b>Início</b></a></li>
            <li className="nav-item"><a className="nav-link link-dark link-opacity-50-hover" href="/produtos"><b>Loja</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/iphones" id="navbarDropdown" role="button" aria-expanded="false"><b>iPhone</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/ipads" id="navbarDropdown" role="button" aria-expanded="false"><b>Ipad</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/macs" id="navbarDropdown" role="button" aria-expanded="false"><b>Mac</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/watchs" id="navbarDropdown" role="button" aria-expanded="false"><b>Watch</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/airpods" id="navbarDropdown" role="button" aria-expanded="false"><b>AirPods</b></a></li>
            <li className="nav-item dropdown"><a className="nav-link link-dark link-opacity-50-hover" href="/acessorios" id="navbarDropdown" role="button" aria-expanded="false"><b>Acessórios</b></a></li>
          </ul>
          <hr />
          {/* Barra de Pesquisa */}
          <form className="d-flex" onSubmit={lidarComPesquisa}>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Pesquisar"
              value={termoDePesquisa}
              onChange={(e) => setTermoDePesquisa(e.target.value)}
            />
            <button className="btn border-0" type="submit">
              <i className="bi bi-search"></i>
            </button>
          </form>
          {/* Exibição do total de resultados */}
          <div className="ms-3">
            {typeof totalResultados === 'number' && (
              <span className="text-muted">{`${totalResultados} ${
                totalResultados === 1 ? 'produto encontrado' : 'produtos encontrados'
              }`}</span>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
export default NavBarProdutos;